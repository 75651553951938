import styled from 'styled-components';
import colors from '@styles/colors';

export const Container = styled.div`
  width: 400px;
`;

export const Title = styled.h2`
  font-size: 18px;
  color: #333;
  text-align: center;
  margin: 20px 0;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  & > label {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const TextArea = styled.textarea`
  height: 80px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;

  &:focus {
    border: 2px solid ${colors.primary};
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;

  button {
    margin-left: 15px;
  }
`;
