/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Input from '@components/Input';
import SelectInput from '@components/SelectInput';
import Modal from '@components/Modal';
import Button from '@components/Button';

import { Container, Title, Row, TextArea, Buttons } from './styles';

const modeloOptions = [
  { label: '55 (NF-e)', value: 55 },
  { label: '65 (NFC-e)', value: 65 },
];

export interface INewInutilizacao {
  modelo: number;
  ano: number;
  serie: number;
  nroInicial: number;
  nroFinal: number;
  justificativa: string;
}

interface IProps {
  onConfirm: (inutilizacao: INewInutilizacao) => void;
  onCancel: () => void;
}

const New: React.FC<IProps> = ({ onConfirm, onCancel }) => {
  const [modeloSel, setModeloSel] = useState(modeloOptions[0]);
  const [ano, setAno] = useState(new Date().getFullYear());
  const [serie, setSerie] = useState();
  const [nroInicial, setNroInicial] = useState();
  const [nroFinal, setNroFinal] = useState();
  const [justificativa, setJustificativa] = useState();

  async function handleConfirm() {
    if (!ano || !serie || !nroInicial || !nroFinal || !justificativa) {
      toast.error('Necessário informar todos os campos!');
      return;
    }

    onConfirm({
      modelo: modeloSel.value,
      ano,
      serie,
      nroInicial,
      nroFinal,
      justificativa,
    });
  }

  return (
    <Modal onCloseRequest={onCancel} exitButton={false}>
      <Container>
        <Title>INUTILIZAÇÃO DE NUMERAÇÃO</Title>
        <Row>
          <label>
            Modelo
            <SelectInput
              fullWidth
              options={modeloOptions}
              defaultValue={modeloOptions[0]}
              value={modeloSel}
              onChange={(newValue: any) => setModeloSel(newValue)}
            />
          </label>
        </Row>
        <Row>
          <label>
            Ano
            <Input
              type="text"
              value={ano}
              onChange={(e: any) => {
                setAno(e.target.value);
              }}
            />
          </label>
          <label>
            Serie
            <Input
              type="text"
              value={serie}
              onChange={(e: any) => {
                setSerie(e.target.value);
              }}
            />
          </label>
        </Row>
        <Row>
          <label>
            Número Inicial
            <Input
              type="text"
              value={nroInicial}
              onChange={(e: any) => {
                setNroInicial(e.target.value);
              }}
            />
          </label>
          <label>
            Número Final
            <Input
              type="text"
              value={nroFinal}
              onChange={(e: any) => {
                setNroFinal(e.target.value);
              }}
            />
          </label>
        </Row>
        <Row>
          <label>
            Justificativa
            <TextArea
              value={justificativa}
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e: any) => {
                setJustificativa(e.target.value);
              }}
            />
          </label>
        </Row>
        <Buttons>
          <Button type="button" onClick={onCancel}>
            Cancelar
          </Button>

          <Button type="button" onClick={() => handleConfirm()}>
            Inutilizar
          </Button>
        </Buttons>
      </Container>
    </Modal>
  );
};

export default New;
